<template>
  <div style="padding:10px;">
    <h1>{{ $t("settings.header") }}</h1>
    <v-layout wrap row style="margin:0;">
      <v-flex sm4>
        <v-card style="margin:5px;" outlined>
          <v-card-title>{{ $t("settings.profile") }}</v-card-title>
          <v-card-text>{{ profile.email }}
            <br>
            <v-btn style="margin-top:10px;" v-if="profile.otp.active === false" color="default" @click="openSetupTwoFactor">{{ $t("settings.setup_two_factor") }}</v-btn>
            <v-btn style="margin-top:10px;" v-if="profile.otp.active === true" color="default" @click="disableTwoFactor">{{ $t("settings.disable_two_factor") }}</v-btn>
            <br>
            <v-btn style="margin-top:10px;" color="default" :loading="l_export" :disabled="l_export" @click="startExport">{{ $t("settings.export_my_data") }}</v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="logout" text>{{ $t("settings.sign_out") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex sm4>
        <v-form @submit="changePassword">
          <v-card style="margin:5px;" outlined>
            <v-card-title>{{ $t("settings.change_password") }}</v-card-title>
            <v-card-text>
              <v-text-field
                type="password"
                :label="$t('settings.current_password')"
                v-model="changepassword_current"
              ></v-text-field>
              <v-text-field type="password" :label="$t('settings.new_password')" v-model="changepassword_new"></v-text-field>
              <v-text-field
                type="password"
                :label="$t('settings.repeat_new_password')"
                v-model="changepassword_new_repeat"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn color="default" type="submit" text>{{ $t("settings.change") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
      <v-flex sm4>
        <v-card outlined style="margin:5px;">
          <v-card-title>{{ $t("settings.language") }}</v-card-title>
          <v-card-text>
            <v-select
              @change="onLanguageChange"
              v-model="language"
              :items="[{text: 'Deutsch', value: 'de'}, {text: 'English', value: 'en'}]"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <br />
    <br />
    <h3>{{ $t("settings.devices") }}</h3>
    <p>{{ $t("settings.devices_description") }}</p>
    <v-layout row wrap style="margin:0;">
      <v-flex xs6 sm4 md3 lg2 v-for="session in sessions" :key="session.id">
        <SessionCard @sessiondeleted="loadActiveSessions" :session="session"></SessionCard>
      </v-flex>
    </v-layout>

    <v-dialog width="500" v-model="d_setup_two_factor">
      <v-card>
        <v-card-title>{{ $t("settings.setup_two_factor") }}</v-card-title>
        <v-card-text style="text-align:center;">
          <img :src="setup_totp_qr" width="200" height="200"/>
          <v-text-field type="text" disabled :value="setup_totp_secret" label="Secret"></v-text-field>
          <div style="margin-top: 30px;">
            <p>{{ $t("settings.type_here_the_six_digit_code") }}</p>
            <v-text-field v-model="setup_totp_code" :label="$t('settings.code')"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="verifyTOTPCode" text>{{ $t("settings.setup") }}</v-btn>
          <v-btn color="default" @click="d_setup_two_factor = false" text>{{ $t("general.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { auth } from "../schmucklicloud";
import { Notifications } from "../notification";

import SessionCard from "../components/SessionCard";

export default {
  data() {
    return {
      profile: {
        email: "",
        otp: {
          active: undefined
        }
      },
      language: window.localStorage.getItem("lang") || "en",
      sessions: [],
      
      d_setup_two_factor: false,
      setup_totp_secret: "",
      setup_totp_qr: "",
      setup_totp_code: "",

      changepassword_current: "",
      changepassword_new: "",
      changepassword_new_repeat: "",

      l_export: false
    };
  },
  mounted() {
    this.getProfileInformation();
    this.loadActiveSessions();
  },
  methods: {
    async getProfileInformation() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.getUserDetails(session_token);
      if (response.isOK) {
        this.profile = response.data;
      }
    },
    async loadActiveSessions() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.getActiveSessions(session_token);
      if (response.isOK) {
        this.sessions = response.data;
      }
    },
    async logout() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.logout(session_token);
      if (response.isOK) {
        localStorage.removeItem("session_token");
        Notifications.show("Logout successful.");
        this.$router.replace("/login");
      } else {
        Notifications.show("There was an error while trying to logout.");
      }
    },
    async openSetupTwoFactor() {
      this.d_setup_two_factor = true;
      var session_token = localStorage.getItem("session_token");

      var response = await auth.generateSetupTOTP(session_token);
      if (response.isOK) {
        this.setup_totp_secret = response.data.secret;
        this.setup_totp_qr = response.data.qr;
      }
    },
    async verifyTOTPCode() {
      var session_token = localStorage.getItem("session_token");

      var response = await auth.verifySetupTOTP(session_token, this.setup_totp_code);
      if (response.isOK) {
        Notifications.show("The two factor authentication has been activated.");
        this.d_setup_two_factor = false;
        this.profile.otp.active = true;
      } else {
        Notifications.show(response.message);
      }
    },
    async disableTwoFactor() {
      var session_token = localStorage.getItem("session_token");
      if (confirm("Do you really want to disable the two factor authentication for this account?")) {
        var response = await auth.disableOTP(session_token);
        if (response.isOK) {
          this.profile.otp.active = false;
          Notifications.show("The two factor authentication has been disabled.");
        } else {
          Notifications.show(response.message);
        }
      }
    },
    async startExport() {
      this.l_export = true;
      var session_token = localStorage.getItem("session_token");
      var response = await auth.requestExport(session_token);
      this.l_export = false;
      if (response.isOK) {
        Notifications.show("An export was sent via email.");
      } else {
        Notifications.show(response.message);
      }
    },
    onLanguageChange() {
      window.localStorage.setItem("lang", this.language);
      this.$root.$i18n.locale = this.language;
    },
    async changePassword(e) {
      e.preventDefault();

      if (this.changepassword_new === this.changepassword_new_repeat) {
        var response = await auth.updatePassword(
          this.profile.email,
          this.changepassword_current,
          this.changepassword_new
        );
        if (response.isOK) {
          Notifications.show("The password has been changed.");
          this.changepassword_current = "";
          this.changepassword_new = "";
          this.changepassword_new_repeat = "";
        } else {
          Notifications.show(response.message);
        }
      }
    }
  },
  components: {
    SessionCard
  }
};
</script>

<style>
</style>