<template>
  <v-card outlined :color="session.current ? 'primary' : 'default'" style="margin:5px;">
    <v-card-title :class="{ allWhite: session.current }">
      <i :class="'fas fa-' + icon"></i>
    </v-card-title>
    <v-card-text :class="{ allWhite: session.current }">
      {{ $t("sessioncard.device_name") }}: {{ session.brand + " " + session.model }}
      <br />
      {{ $t("sessioncard.device_os") }}: {{ session.os.name + " " + session.os.version }}
      <br />
      {{ $t("sessioncard.started") }}: {{ parseDateTime(moment((new Date(session.session_started.replace(" ","T"))).getTime()).tz("Europe/Zurich")) }}
    </v-card-text>
    <v-card-actions>
      <v-btn text :disabled="session.current" color="error" @click="deleteSession">{{ $t("sessioncard.delete") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { auth } from "../schmucklicloud";

export default {
  props: {
    session: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.session.device) {
        case "smartphone":
          return "mobile";
        case "desktop":
          return "desktop";
        default:
          return "mobile";
      }
    }
  },
  methods: {
    async deleteSession() {
      var session_token = localStorage.getItem("session_token");
      var response = await auth.removeSession(session_token, this.session.id);
      if (response.isOK) {
        this.$emit("sessiondeleted");
      }
    },
    parseDateTime(time) {
      var date = new Date();
      date.setTime(time);
      return (
        (date.getDate() + "").padStart(2, "0") +
        "." +
        (date.getMonth() + 1 + "").padStart(2, "0") +
        "." +
        date.getFullYear() +
        " " +
        (date.getHours() + "").padStart(2, "0") +
        ":" +
        (date.getMinutes() + "").padStart(2, "0")
      );
    }
  }
};
</script>

<style>
.allWhite{
  color: white !important;
}
</style>